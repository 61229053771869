<template>
  <div class="data-generate cosmetic-attack">
    <el-tabs v-model="tabActiveKey" @tab-click="changeTab" class="custom-tab"> 
      <el-tab-pane :label="item.text" :name="item.value" v-for="(item, index) in tabList" :key="index"></el-tab-pane>
    </el-tabs>
    <div class="info-tip">作为针对目标检测系统的一种反制手段，让检测系统算法失效，可以达到躲避隐身、冒充身份等目的。人脸躲避：使目标检测系统无法识别目标人脸；人脸冒充：使目标检测系统将目标人脸识别为其他人物</div>
    <div class="generate-content">
      <div class="generate-area">
        <!-- <div class="left-tab">
          <div class="item" v-for="(item, index) in tabList" :key="index" :class="tabActiveKey == item.value ? 'active' : ''" @click="changeTab(item.value)">{{item.text}}</div>
        </div> -->
        <div class="under-attack-single" v-if="tabActiveKey == 'avoid'">
          <div class="custom-block inner">
            <h4 class="sub-title">被攻击方</h4>
            <el-image :src="customData.underAttackUrl" v-if="customData.underAttackUrl" lazy fit="contain">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <div v-else class="uploader" @click="chooseExampleFile('underAttackUrl')">
              <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
            </div>
            <div class="upload-desc" :class="customData.loading ? 'disabled' : ''">
              <div class="upload-btn">
                <div class="upload-text" @click="chooseExampleFile('underAttackUrl')">
                  <p class="text">上传图片</p>
                </div>
              </div>
              <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
            </div>
          </div>
        </div>
        <div class="data-source" v-else>
          <div class="attack">
            <div class="custom-block inner">
              <h4 class="sub-title attack">攻击方</h4>
              <el-image :src="customData.attackUrl" v-if="customData.attackUrl" lazy fit="contain">
                <div slot="placeholder" v-loading="true" class="loading"></div>
              </el-image>
              <div v-else class="uploader" @click="chooseExampleFile('attackUrl')">
                <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
              </div>
              <div class="upload-desc" :class="customData.loading ? 'disabled' : ''">
                <div class="upload-btn">
                  <div class="upload-text" @click="chooseExampleFile('attackUrl')">
                    <p class="text">上传图片</p>
                  </div>
                </div>
                <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
              </div>
            </div>
          </div>
          <div class="under-attack">
            <div class="custom-block inner">
              <h4 class="sub-title">被攻击方</h4>
              <el-image :src="customData.underAttackUrl" v-if="customData.underAttackUrl" lazy fit="contain">
                <div slot="placeholder" v-loading="true" class="loading"></div>
              </el-image>
              <div v-else class="uploader" @click="chooseExampleFile('underAttackUrl')">
                <img src="@/assets/img/icon-upload.png" alt="" class="icon-upload">
              </div>
              <div class="upload-desc" :class="customData.loading ? 'disabled' : ''">
                <div class="upload-btn">
                  <div class="upload-text" @click="chooseExampleFile('underAttackUrl')">
                    <p class="text">上传图片</p>
                  </div>
                </div>
                <!-- <p class="desc">请上传包含正面人脸的图片</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="custom-block result-box">
          <h4 class="sub-title result">生成结果</h4>
          <div class="image-cover" v-if="!customData.loading">
            <el-image :src="customData.resultUrl" v-if="customData.resultUrl" lazy fit="contain">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <div class="no-data-panel" v-else>
              <img src="@/assets/img/icon-no-data.png" alt="" width="120" height="120">
              <p>暂无数据</p>
            </div>
          </div>
          <div class="qr-scanner" v-if="customData.loading || customData.discernLoading">
            <div class="box">
              <div class="line"></div>
              <div class="angle"></div>
            </div>
            <p class="tip" v-if="customData.loading">在线攻击可能需要几分钟，请稍候</p>
          </div>
          <div class="btn-group" v-if="customData.resultUrl && !customData.loading">
            <div class="btn" @click="onDiscern">
              <span>人脸识别</span>
            </div>
            <div class="btn" @click="downloadImage">
              <img src="@/assets/img/icon-export.png" alt="" width="16" height="16">
              <span>导出</span>
            </div>
          </div>
          <div class="discern-panel" v-if="customData.showDiscern">
            <i class="el-icon-close" @click="closeDiscern"></i>
            <div class="info">
              <el-image :src="originUrl" lazy fit="contain">
                <div slot="placeholder" v-loading="true" class="loading"></div>
              </el-image>
              <div class="desc">
                <p class="result-font">
                  <i class="icon-result" :class="result ? 'el-icon-success' : 'el-icon-error'"></i>
                  <span>{{result ? '认证成功' : '认证失败'}}</span>
                </p>
                <p>{{result ? '识别到目标人物' : '无法识别目标人物'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" @click="onCreate" :disabled="btnDisabled">开始生成</el-button>
    </div>

    <choose-example-radio
      v-if="chooseShowRadio"
      :dataList="customExampleList"
      @closeDialogItem="hideExampleRadio"
      :type="activeKey"
      :fileType="fileType"
      @onChoose="onChoose">
    </choose-example-radio>
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import { Loading } from 'element-ui';
import { STORAGE, CODE_CLASS } from '@/utils/const';
import { downloadImage, goLoginPageAndInitData } from '@/utils';
import ChooseExampleRadio from "@/components/ChooseExampleRadio.vue";

const ACTIVE_KEY = 'makeUpAttackData';

const TAB_LIST = [
  {text: '人脸躲避', value: 'avoid'},
  {text: '人脸冒充', value: 'impersonation'}
]

export default {
  name: "cosmetic-attack",
  components: {
    "choose-example-radio": ChooseExampleRadio,
  },
  data() {
    return {
      tabList: TAB_LIST,
      tabActiveKey: TAB_LIST[0].value,
      underAttackUrl: 'http://saas.ruijianai.com:9311/mai/image-files/qb_create/make_up/base-demo.jpg', // 暂时写死

      activeKey: ACTIVE_KEY,
      chooseShowRadio: false,
      fileType: CODE_CLASS.moduleKey[ACTIVE_KEY],
      activeUploadKey: '', // 上传后需要对应的字段
    }
  },

  mounted() {
    this.getDemo();
  },

  computed: {
    demoKey() {
      return CODE_CLASS.demoKey[`${ACTIVE_KEY}_${this.tabActiveKey}_${this.activeUploadKey}`];
    },
    customExampleList() {
      return this.activeUploadKey ? state.demoData[this.demoKey] : [];
    },
    data() {
      return state[ACTIVE_KEY] || {};
    },

    customData() {
      return this.data[this.tabActiveKey] || {}
    },
    
    btnDisabled() {
      return (this.tabActiveKey == 'avoid' ? !this.customData.underAttackUrl : (!this.customData.attackUrl || !this.customData.underAttackUrl)) || this.customData.loading;
    },

    // 人脸识别结果
    result() {
      let {results = {}} = this.customData;
      return this.tabActiveKey == 'avoid' ? !results.facenet : results.facenet;
    },

    // 原始图片
    originUrl() {
      return  this.customData.underAttackUrl;
    },
  },

  methods: {
    getDemo() {
      dispatch(ACTIONS.getDemo, {fileType: CODE_CLASS.demoKey[`${ACTIVE_KEY}_avoid_underAttackUrl`]});
      dispatch(ACTIONS.getDemo, {fileType: CODE_CLASS.demoKey[`${ACTIVE_KEY}_impersonation_attackUrl`]});
      dispatch(ACTIONS.getDemo, {fileType: CODE_CLASS.demoKey[`${ACTIVE_KEY}_impersonation_underAttackUrl`]});
    },

    // 切换tab
    changeTab(item) {
      this.tabActiveKey = item.name;
    },

    commitData(data) {
      commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, data);
    },

    // 开始生成
    onCreate() {
      let {tabActiveKey} = this;
      let {attackUrl, underAttackUrl} = this.customData;
      dispatch(ACTIONS.makeUpAttack, {
        data: {fileUrl: tabActiveKey == 'avoid' ? underAttackUrl : attackUrl, type: tabActiveKey == 'avoid' ? 0 : 1, imageTarget: tabActiveKey == 'avoid' ? '' : underAttackUrl
        },
        activeKey: tabActiveKey,
      });
    },

    // 人脸识别
    onDiscern() {
      let {tabActiveKey} = this;
      let {attackUrl, underAttackUrl} = this.customData;
      commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
        ...this.data,
        [tabActiveKey]: {
          ...this.data[tabActiveKey],
          discernLoading: true,
          showDiscern: false
        }
      });
      let time = Math.random(0, 1) * 1000 + 1000;
      setTimeout(() => {
        commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
          ...this.data,
          [tabActiveKey]: {
            ...this.data[tabActiveKey],
            discernLoading: false,
            showDiscern: true
          }
        });
      }, time)
    },

    // 关闭认证结果
    closeDiscern() {
      let {tabActiveKey} = this;
      commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
        ...this.data,
        [tabActiveKey]: {
          ...this.data[tabActiveKey],
          discernLoading: false,
          showDiscern: false
        }
      });
    },

    downloadImage() {
      let {resultUrl} = this.customData;
      resultUrl && downloadImage(resultUrl);
    },

    // 选择示例
    chooseExampleFile(key) {
      if (this.customData.loading) {
        return;
      }
      this.chooseShowRadio = true;
      this.activeUploadKey = key;
    },
   
    hideExampleRadio(){
      this.chooseShowRadio = false;
    },

    // 选择
    onChoose(fileUrl) {
      this.chooseShowRadio = false;
      this.commitData({...this.data, [this.tabActiveKey]: {
        ...this.data[this.tabActiveKey],
        [this.activeUploadKey]: fileUrl,
        resultUrl: '',
        results: {},
        discernLoading: false,
        showDiscern: false,
        loading: false,
      }});
    },
  },
};
</script>

<style lang="less">
</style>
